import React, { useState } from 'react';
import './Products.css';

const Products = ({ userRole }) => {
  // List of product names without images
  const productNamesList = [
    'Ventilators',
    'Patient Monitor',
    'ECG Machine',
    'Autoclaves (sterilizers)',
    'Cautery Machine',
    'X-ray machines (for diagnosis and treatment)',
    'Ultrasound',
    'Pulse oximeter',
    'Bp instrument',
    'Stethoscopes',
    'Dopplers',
    'EKG (electrocardiogram)',
    'Patient Bed',
    'Wheelchairs',
    'Glucometers',
    'Procedure tables',
    'Examination Light',
    'Adjustable height tables',
    'Weighing scales',
    'Height measures',
  ];

  // State to manage the list of products with images
  const [products, setProducts] = useState([
    { name: 'Anaesthesia Machine', imageUrl: '/images/Anaesthesia Machine.jpg' },
    { name: 'Bipap', imageUrl: '/images/bipap.jpg' },
    { name: 'Cautery Machine', imageUrl: '/images/cautery machine.jpg' },
    { name: 'Patient Monitor', imageUrl: '/images/patient monitor.jpg' },
    { name: 'Ventilators', imageUrl: '/images/Ventilators.jpg' },
  ]);

  // State for adding a new product
  const [newProduct, setNewProduct] = useState({ name: '', imageUrl: '' });

  // Handle adding a product
  const handleAddProduct = () => {
    if (newProduct.name && newProduct.imageUrl) {
      setProducts([...products, newProduct]);
      setNewProduct({ name: '', imageUrl: '' }); // Reset the input fields
    } else {
      alert('Please enter valid product details.');
    }
  };

  // Handle deleting a product
  const handleDeleteProduct = (indexToDelete) => {
    const updatedProducts = products.filter((_, index) => index !== indexToDelete);
    setProducts(updatedProducts);
  };

  return (
    <section id="products">
      <h2>{userRole === 'admin' ? 'Admin Panel: Manage Products' : 'Our Products'}</h2>

      {/* List of Product Names */}
      <ul className="product-names">
        {productNamesList.map((productName, index) => (
          <li key={index}>{productName}</li>
        ))}
      </ul>

      <div className="products-container">
        {products.map((product, index) => (
          <div key={index} className="product-card">
            <h3>{product.name}</h3>
            <img
              src={product.imageUrl}
              alt={product.name}
              style={{ width: '200px', height: 'auto', borderRadius: '8px' }}
            />

            {/* Admin features: Show delete button for admins */}
            {userRole === 'admin' && (
              <button
                onClick={() => handleDeleteProduct(index)}
                style={{
                  backgroundColor: 'red',
                  color: 'white',
                  marginLeft: '10px',
                  padding: '5px 10px',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                Delete
              </button>
            )}
          </div>
        ))}
      </div>

      {/* Admin Panel for Adding Products */}
      {userRole === 'admin' && (
        <div className="admin-panel">
          <h3>Add New Product</h3>
          <input
            type="text"
            placeholder="Product Name"
            value={newProduct.name}
            onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
          />
          <input
            type="text"
            placeholder="Image URL"
            value={newProduct.imageUrl}
            onChange={(e) => setNewProduct({ ...newProduct, imageUrl: e.target.value })}
          />
          <button onClick={handleAddProduct}>Add Product</button>
        </div>
      )}
    </section>
  );
};

export default Products;
