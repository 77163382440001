import React, { useState } from 'react';
import Header from './components/Header.js';
import Nav from './components/Nav.js';
import Home from './components/Home';
import About from './components/About';
import Products from './components/Products';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './App.css';

const App = () => {
  // State to manage user role (can be 'admin' or 'customer')
  const [userRole] = useState('customer'); // Set to 'admin' for testing, or change to 'customer'

  return (
    <div>
      <Header />
      <Nav />
      <Home />
      <Products userRole={userRole} />
      <Contact />
      <About />
      <Footer />
    </div>
  );
};

export default App;
