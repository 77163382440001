import React from 'react';
import backgroundImage from '../assets/Image1.png';
import './Home.css';

const Home = () => (
  <section
    id="home"
    className="home"
    style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      color: '#030000',
      padding: '100px 20px',
      textAlign: 'center',
    }}
  >
    <h2>Welcome to Ashri Enterprises</h2>
    <p>
      We offer a range of refurbished and new medical equipment, along with all necessary accessories. 
      <br />
      Contact us for your medical equipment needs!
    </p>
  </section>
);

export default Home;
