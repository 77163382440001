import React from 'react';
import './Nav.css'; // Optional

const Nav = () => (
  <nav className="nav">
    <a href="#home">Home</a>
    <a href="#products">Products</a>
    <a href="#contact">Contact</a>
    <a href="#about">About Us</a>
  </nav>
);

export default Nav;
