import React from 'react';
// If logo is in src/assets/
import logo from '../assets/logo.png'; // Adjust path based on actual location
import './Header.css';

const Header = () => (
  <header className="header">
    <img src={logo} alt="Ashri Enterprises Logo" className="logo" />
    <div>
      <h1 className="title">Ashri Enterprises</h1>
      <p>Complete Solutions of Medical Equipments</p>
    </div>
  </header>
);

export default Header;
