import React from 'react';
import './Contact.css';

const Contact = () => (
  <section id="contact">
    <h2>Contact Us</h2>
    <form>
      <label>Name:</label>
      <input type="text" placeholder="Enter your name" required /><br />
      
      <label>Email:</label>
      <input type="email" placeholder="Enter your email" required /><br />
      
      <label>Message:</label>
      <textarea placeholder="Write your message here" required></textarea><br />
      
      <button type="submit">Submit</button>
    </form>
    <address>
      Email: <a href="mailto:ashrienterprises07@gmail.com">ashrienterprises07@gmail.com</a><br />
      Phone: +91 7317468661 <br />
      Address: 1328, Osho Nagar, Near Kanausi, Lucknow - 226023
    </address>
  </section>
);

export default Contact;
