import React from 'react';

const About = () => (
  <section id="about">
    <h2>About Us</h2>
    <p>
      At Ashri Enterprises, our unwavering commitment revolves around delivering unparalleled excellence. 
      We pride ourselves on consistently providing the highest standards of quality, ensuring that every product 
      and service meets not only industry benchmarks but exceeds customer expectations. Our dedication extends beyond 
      mere satisfaction; we aim to create a lasting and delightful experience for our valued customers.
    </p>
  </section>
);

export default About;
